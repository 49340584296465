import spainBankAccountNumberValidator from '@/utils/es/spainBankAccountNumberValidator'

/**
 * Based on this: https://www.npmjs.com/package/ibantools
 */

const countrySpecs: {
  [char: string]: { chars: number; bbanRegexp: string; IBANRegistry: boolean; SEPA: boolean }
} = {
  ES: { chars: 24, bbanRegexp: '^[0-9]{20}$', IBANRegistry: true, SEPA: true },
  PL: { chars: 28, bbanRegexp: '^[0-9]{24}$', IBANRegistry: true, SEPA: true },
  RO: { chars: 24, bbanRegexp: '^([a-zA-Z]{4})(?:-)?(\\d{16})$', IBANRegistry: true, SEPA: true }
}

/**
 * Check BBAN format
 * @return {boolean} valid
 * @param bban
 * @param bformat
 */
function checkFormatBBAN(bban: string, bformat: string): boolean {
  const reg = new RegExp(bformat, '')
  return reg.test(bban)
}

/**
 * Calculate checksum of IBAN and compares it with checksum provided in IBANregistry
 * @param {string} iban
 * @return {boolean}
 */
function isValidIBANChecksum(iban: string): boolean {
  const providedChecksum = parseInt(iban.slice(2, 4), 10)
  const temp = `${iban.slice(3) + iban.slice(0, 2)}00`
  let validationString = ''
  for (let n = 1; n < temp.length; n += 1) {
    const c = temp.charCodeAt(n)
    if (c >= 65) {
      validationString += (c - 55).toString()
    } else {
      validationString += temp[n]
    }
  }
  while (validationString.length > 2) {
    const part = validationString.slice(0, 6)
    validationString = (parseInt(part, 10) % 97).toString() + validationString.slice(part.length)
  }
  const rest = parseInt(validationString, 10) % 97
  return 98 - rest === providedChecksum
}

/**
 * Validate IBAN
 * @example
 * // returns true
 * isValidIBAN("NL91ABNA0517164300");
 * @example
 * // returns false
 * isValidIBAN("NL92ABNA0517164300");
 * @param {string} iban
 * @return {boolean} true/false
 */
export default function isValidIBAN(iban: string): boolean {
  if (!iban) {
    return false
  }
  const reg = /^[0-9]{2}$/
  const countryCode = iban.slice(0, 2)
  const spec = countrySpecs[countryCode]

  const ibanValidation =
    spec !== undefined &&
    spec.chars === iban.length &&
    reg.test(iban.slice(2, 4)) &&
    checkFormatBBAN(iban.slice(4), spec.bbanRegexp) &&
    isValidIBANChecksum(iban)

  if (countryCode === 'ES') {
    return ibanValidation && spainBankAccountNumberValidator(iban.substring(4))
  }

  return ibanValidation
}
